import {
  Button,
  Heading,
  VStack,
  Stack,
  Divider,
  Link,
  Tag,
  TagLabel,
  TagLeftIcon,
  HStack,
  Select,
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
// @ts-ignore
import SyntaxHighlighter from "react-syntax-highlighter";
// @ts-ignore
import { anOldHope } from "react-syntax-highlighter/dist/esm/styles/hljs";

import { ReactComponent as Logo } from "./assets/logo.svg";
import { useNavigate } from "react-router-dom";
import { NBAPricing } from "./NBAPricing";
import { NFLPricing } from "./NFLPricing";
import React from "react";
import { MLBPricing } from "./MLBPricing";
import { AddIcon } from "@chakra-ui/icons";
import { NBA_RESPONSE, NFL_RESPONSE, MLB_RESPONSE } from "./constants";

export const Landing = () => {
  const navigate = useNavigate();
  const [selectedSport, setSelectedSport] = React.useState("nba");
  return (
    <VStack w="100%">
      <VStack align="center" w="100%" marginY={32}>
        <Logo width="50%" height="50%" />
        <Heading size="lg">The best API for NBA, NFL, and MLB data</Heading>
        <Button
          style={{ marginTop: 30 }}
          w={["90%", "20%"]}
          colorScheme="teal"
          onClick={() => navigate("/signup")}
        >
          Sign Up for Free
        </Button>
      </VStack>
      <Divider width="75%" />
      <LanguageLibraries />
      <VStack width="100%" style={{ marginTop: 40, marginBottom: 75 }}>
        <Heading size="md" marginBottom={10}>
          BUILT BY WORLD-CLASS ENGINEERS TO FIT EVERY NEED
        </Heading>
        <Stack
          direction={["column", "row"]}
          alignItems="stretch"
          width="75%"
          justify="center"
        >
          <VStack flex={1} alignItems="center" justify="center">
            <Tag size="lg" variant="subtle" colorScheme="teal">
              <TagLeftIcon boxSize="12px" as={AddIcon} />
              <TagLabel>Data Analytics</TagLabel>
            </Tag>
            <Tag size="lg" colorScheme="teal">
              <TagLeftIcon boxSize="12px" as={AddIcon} />
              <TagLabel>Sports News and Media</TagLabel>
            </Tag>
            <Tag size="lg" variant="subtle" colorScheme="teal">
              <TagLeftIcon boxSize="12px" as={AddIcon} />
              <TagLabel>Betting Platforms</TagLabel>
            </Tag>
          </VStack>
          <VStack flex={1} alignItems="center" justify="center">
            <Tag size="lg" variant="subtle" colorScheme="teal">
              <TagLeftIcon boxSize="12px" as={AddIcon} />
              <TagLabel>Fantasy Sports</TagLabel>
            </Tag>
            <Tag size="lg" variant="subtle" colorScheme="teal">
              <TagLeftIcon boxSize="12px" as={AddIcon} />
              <TagLabel>Player Performance Tracking</TagLabel>
            </Tag>
            <Tag size="lg" variant="subtle" colorScheme="teal">
              <TagLeftIcon boxSize="12px" as={AddIcon} />
              <TagLabel>Internal Tools for Teams</TagLabel>
            </Tag>
          </VStack>
          <VStack flex={1} alignItems="center" justify="center">
            <Tag size="lg" variant="subtle" colorScheme="teal">
              <TagLeftIcon boxSize="12px" as={AddIcon} />
              <TagLabel>Scoreboard Apps</TagLabel>
            </Tag>
            <Tag size="lg" variant="subtle" colorScheme="teal">
              <TagLeftIcon boxSize="12px" as={AddIcon} />
              <TagLabel>Machine Learning Data</TagLabel>
            </Tag>
            <Tag size="lg" variant="subtle" colorScheme="teal">
              <TagLeftIcon boxSize="12px" as={AddIcon} />
              <TagLabel>Predictive Modeling</TagLabel>
            </Tag>
          </VStack>
        </Stack>
      </VStack>
      <VStack style={{ marginBottom: "40px" }}>
        <Heading size="sm">Select League</Heading>
        <Stack
          direction={["column", "row"]}
          alignItems="center"
          justifyContent="center"
        >
          <Button
            size="lg"
            colorScheme="teal"
            variant={selectedSport === "nba" ? "solid" : "outline"}
            onClick={() => setSelectedSport("nba")}
          >
            NBA
          </Button>
          <Button
            size="lg"
            colorScheme="teal"
            variant={selectedSport === "nfl" ? "solid" : "outline"}
            marginX={18}
            onClick={() => setSelectedSport("nfl")}
          >
            NFL
          </Button>
          <Button
            size="lg"
            colorScheme="teal"
            variant={selectedSport === "mlb" ? "solid" : "outline"}
            onClick={() => setSelectedSport("mlb")}
          >
            MLB
          </Button>
        </Stack>

        <Stack direction={["column", "row"]} spacing={16}>
          {selectedSport === "nba" && (
            <VStack>
              <Link
                size="xs"
                color="teal.500"
                href="https://docs.balldontlie.io"
                isExternal
              >
                view docs
              </Link>
              <NBAPricing />
            </VStack>
          )}

          {selectedSport === "nfl" && (
            <VStack>
              <Link
                size="xs"
                color="teal.500"
                href="https://nfl.balldontlie.io"
                isExternal
              >
                view docs
              </Link>
              <NFLPricing />
            </VStack>
          )}

          {selectedSport === "mlb" && (
            <VStack>
              <Link
                size="xs"
                color="teal.500"
                href="https://mlb.balldontlie.io"
                isExternal
              >
                view docs
              </Link>
              <MLBPricing />
            </VStack>
          )}
        </Stack>
      </VStack>
      <TryItOut />
    </VStack>
  );
};

const TryItOut = () => {
  const [curr, setCurr] = React.useState("nba");
  const [res, setRes] = React.useState<any>();

  const onChange = (val: any) => {
    setCurr(val.target.value);
    setRes(undefined);
  };

  const renderResponse = () => {
    if (!res) {
      return null;
    }

    const response =
      curr === "nba"
        ? NBA_RESPONSE
        : curr === "nfl"
          ? NFL_RESPONSE
          : MLB_RESPONSE;
    const json = JSON.stringify(response, null, 2);

    return (
      <SyntaxHighlighter
        customStyle={{ width: "100%", maxHeight: 700, overflow: "scroll" }}
        language="json"
        style={anOldHope}
      >
        {json}
      </SyntaxHighlighter>
    );
  };

  return (
    <VStack w="60%">
      <Heading size="md">TEST THE API</Heading>
      <HStack w="100%">
        <Select isReadOnly flex={1} onChange={onChange} borderColor="teal">
          <option value="nba">/nba/v1/games?dates[]=2024-11-13</option>
          <option value="nfl">/nfl/v1/games?seasons[]=2024&week[]=10</option>
          <option value="mlb">/mlb/v1/games?team_ids[]=19&season=2024</option>
        </Select>
        <Button onClick={() => setRes(curr)} colorScheme="teal">
          Send
        </Button>
      </HStack>
      {renderResponse()}
    </VStack>
  );
};

const LanguageLibraries = () => {
  return (
    <VStack padding={12} w="100%">
      <VStack flex={1} display="flex" justify="center">
        <Heading size="lg">Get Started in Minutes</Heading>
        <Text textAlign="center">
          We provide libraries for Javascript/Typescript and Python to make it
          easy to interact with our API. Look out for more language support in
          the near future.
        </Text>
        <HStack style={{ marginTop: 24, marginBottom: 24 }}>
          <Button
            onClick={() =>
              window.open("https://github.com/balldontlie-api/typescript")
            }
            colorScheme="teal"
          >
            Javascript/TS Docs
          </Button>
          <Button
            onClick={() =>
              window.open("https://github.com/balldontlie-api/python")
            }
            colorScheme="teal"
          >
            Python Docs
          </Button>
        </HStack>
      </VStack>
      <Tabs flex={1} variant="enclosed" colorScheme="teal">
        <TabList>
          <Tab>Javascript/TS</Tab>
          <Tab>Python</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <SyntaxHighlighter
              showLineNumbers
              customStyle={{
                width: "100%",
              }}
              language="typescript"
              style={anOldHope}
            >
              {`import { BalldontlieAPI } from "@balldontlie/sdk";

const api = new BalldontlieAPI({ apiKey: "your-api-key" });

// Get NBA stats
await api.nba.getStats({ dates: ["2024-11-13", "2024-11-14"] });

// Get NFL stats
await api.nfl.getStats({ seasons: [2024] });

// Get MLB stats
await api.mlb.getStats({ player_ids: [208] });`}
            </SyntaxHighlighter>
          </TabPanel>
          <TabPanel>
            <SyntaxHighlighter
              showLineNumbers
              customStyle={{
                width: "100%",
              }}
              language="python"
              style={anOldHope}
            >
              {`from balldontlie import BalldontlieAPI

api = BalldontlieAPI(api_key="your-api-key")

# Get NBA stats
api.nba.stats.list(dates=["2024-11-13", "2024-11-14"])

# Get NFL stats
api.nfl.stats.list(seasons=[2024])

# Get MLB stats
api.mlb.stats.list(player_ids=[208])`}
            </SyntaxHighlighter>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
};
