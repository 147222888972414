import { useContext } from "react";
import { AppContext } from "./context";
import {
  Text,
  Button,
  Flex,
  HStack,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  VStack,
  useClipboard,
  useToast,
  Divider,
} from "@chakra-ui/react";
import React from "react";
import {
  createCheckoutSession,
  createManageSubscriptionSession,
  registerStripeCustomer,
} from "./api";
import { Subscription } from "./types";

export const Home = () => {
  const { user, setUser } = useContext(AppContext);
  const toast = useToast();
  const { onCopy, setValue, hasCopied } = useClipboard("");

  const nbaSub = user?.subscriptions?.find((s) => s.type === "nba");
  const nflSub = user?.subscriptions?.find((s) => s.type === "nfl");
  const mlbSub = user?.subscriptions?.find((s) => s.type === "mlb");

  React.useEffect(() => {
    if (user?.apiKey) {
      setValue(user?.apiKey);
    }
  }, [user?.apiKey, setValue]);

  // If stripe_session_id is set, the customer arrived here after successfully checking out.
  // We need to call an endpoint that will fetch the stripe customer based on the session_id and update
  // the users.stripe_customer_id column.
  const query = new URLSearchParams(window.location.search);
  const sessionId = query.get("stripe_session_id");
  const addCustomer = React.useCallback(
    async (sid: string) => {
      try {
        const user = await registerStripeCustomer({ stripeSessionId: sid });
        setUser(user);
      } catch (e) {
        console.log(e);
      }
    },
    [setUser]
  );
  React.useEffect(() => {
    if (sessionId) {
      addCustomer(sessionId);
      toast({
        title: "Tier Upgraded",
        description:
          "It may take a few minutes for changes to take effect. Please check back in a minute.",
        status: "success",
        isClosable: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, addCustomer]);

  const onNewCustomer = async (
    tier: "paid" | "paid_plus",
    type: "nfl" | "nba" | "mlb"
  ) => {
    const url = await createCheckoutSession(tier, type);
    window.open(url);
  };

  const subChange = query.get("stripe_subscription_change") === "true";
  React.useEffect(() => {
    if (subChange) {
      toast({
        title: "Account Changed",
        description: "It may take a few minutes for changes to take effect.",
        status: "success",
        isClosable: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subChange]);

  const onChangeSubscription = async () => {
    const url = await createManageSubscriptionSession();
    window.open(url);
  };

  const renderButtons = (sub: Subscription) => {
    return (
      <HStack>
        {sub?.tier === "free" ? (
          <>
            <Button
              size="sm"
              variant="outline"
              colorScheme="teal"
              onClick={() => onNewCustomer("paid", sub.type)}
            >
              Upgrade to ALL-STAR
            </Button>
            <Button
              size="sm"
              variant="solid"
              colorScheme="teal"
              onClick={() => onNewCustomer("paid_plus", sub.type)}
            >
              Upgrade to GOAT
            </Button>
          </>
        ) : (
          <Button size="sm" colorScheme="red" onClick={onChangeSubscription}>
            Cancel Subscription
          </Button>
        )}
      </HStack>
    );
  };

  return (
    <Flex h="100%" my={4}>
      <Flex flex={2} />
      <Flex justify="center" align="center" h="100%">
        <VStack alignItems="flex-start" mb={6} h="100%">
          <StatGroup flexDir="column">
            <Stat>
              <StatLabel>Email</StatLabel>
              <StatNumber>{user?.email}</StatNumber>
            </Stat>
            <Stat my={5}>
              <StatLabel>
                <HStack>
                  <Text>API Key</Text>
                  <Button size="xs" onClick={onCopy}>
                    {hasCopied ? "Copied!" : "Copy"}
                  </Button>
                </HStack>
              </StatLabel>
              <StatNumber>{user?.apiKey}</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>NBA Tier</StatLabel>
              <StatNumber>
                {nbaSub?.tier === "free"
                  ? "Rookie"
                  : nbaSub?.tier === "paid"
                    ? "All-Star"
                    : "GOAT"}
                {renderButtons(nbaSub!)}
              </StatNumber>
            </Stat>
            <Stat marginTop={4}>
              <StatLabel>NFL Tier</StatLabel>
              <StatNumber>
                {nflSub?.tier === "free"
                  ? "Rookie"
                  : nflSub?.tier === "paid"
                    ? "All-Star"
                    : "GOAT"}
                {renderButtons(nflSub!)}
              </StatNumber>
            </Stat>
            <Stat marginTop={4}>
              <StatLabel>MLB Tier</StatLabel>
              <StatNumber>
                {mlbSub?.tier === "free"
                  ? "Rookie"
                  : mlbSub?.tier === "paid"
                    ? "All-Star"
                    : "GOAT"}
                {renderButtons(mlbSub!)}
              </StatNumber>
            </Stat>
          </StatGroup>
          <Divider />
          <Text>
            Canceling a subscription will end access immediately, not at the end
            of the billing period.
          </Text>
        </VStack>
      </Flex>
      <Flex flex={2} />
    </Flex>
  );
};
